import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery, Link } from "gatsby";

const AllEventLinks = styled.div`
  background-color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;

  h2 {
    padding: 0px 16px;
    color: white;
  }

  ul {
    padding: 0px 16px;
    list-style-type: none;
    line-height: 200%;
  }

  a {
    text-decoration: none;
    color: #52c2f0;
  }
`

const AllEventsList = () => {
  return (
    <React.Fragment>
      <StaticQuery
        query={graphql`
          query {
            allAirtable(filter: { table: { eq: "Events" } }, sort: {
              fields: [data___start_date_event]
              order: [ASC]
            }) {
              edges {
                node {
                  id
                  data {
                    name_event
                    start_date_event
                    end_date_event
                    url_slug_event
                  }
                }
              }
            }
          }
        `}
        render={(props) => (
            <AllEventLinks>
            <h2>
              Festivals
            </h2>
          
            <ul>
              {props.allAirtable.edges.map((event) => (
                <li key={event.node.id}>
                  <Link to={`/${event.node.data.url_slug_event}`}>
                    {event.node.data.name_event}
                  </Link>
                </li>
              ))}
            </ul>
            </AllEventLinks>
        )}
      />
    </React.Fragment>
  );
};

export default AllEventsList;
