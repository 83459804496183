import React from "react";

//Layout
import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";
import Card from "../components/Layout/Card";

//Components
import SEO from "../components/seo"

//Lists
import TodayTomorrowList from "../components/CategoryViews/TodayTomorrowList";
import AllEventsList from "../components/CategoryViews/AllEventsList";
import AllGenresList from "../components/CategoryViews/AllGenresList";
import AllVenuesList from "../components/CategoryViews/AllVenuesList";


const FourOFourPage = ({ data }) => {
  let pageContext = { data: {} }
  pageContext.data.desc_event = "404"
  pageContext.data.name_event = "404"

  return (
    <React.Fragment>
      <section>
        <SEO context={pageContext} />
        <Main>
          <FlexContainer>
            <FlexItem>
              <Card>404: Something went wrong. Probably the venue, festival or genre has no events currently. Sorry.</Card>
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <TodayTomorrowList />   
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllVenuesList />        
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllEventsList />        
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <AllGenresList />        
            </FlexItem>
          </FlexContainer>
        </Main>
      </section>
    </React.Fragment>
  );
};

export default FourOFourPage;