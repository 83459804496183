import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const AllEventLinks = styled.div`
  background-color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;

  h2 {
    padding: 0px 16px;
    color: white;
  }

  ul {
    padding: 0px 16px;
    list-style-type: none;
    line-height: 200%;
  }

  a {
    text-decoration: none;
    color: #52c2f0;
  }
`

const AllEventsList = () => {
  return (
    <AllEventLinks>
    <h2>
      Upcoming
    </h2>
    <ul>
      <li><Link to={`/today`}>Today</Link></li>
      <li><Link to={`/tomorrow`}>Tomorrow</Link></li>
    </ul>
    </AllEventLinks>
  );
};

export default AllEventsList;
